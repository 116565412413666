import "./Profiles.css";
const Citst = () => {
  return (
    <div className="container">
      <div className="row" id="main-title">
        <h1>Centrul IT pentru Stiinta si Tehnologie(CITST)</h1>
      </div>
      <div className="row" id="partner-intro">
        <div className="col">
          <div id="partnerInroduction">
            CITST, Centrul IT pentru Stiinta si Tehnologie, (RO) is a
            research-oriented SME targeting integrative approaches to
            interdisciplinary research. Since it was established in 2007, CITST
            has been involved in several R&D projects receiving both national
            grants (16 projects) and international grants (2 FP7, 5 Horizon
            2020). The main areas of expertise are in information technology and
            user-oriented services. Currently, CITST was involved in the
            following AAL projects as partner organization or coordinator:
            NITICS, CAMI, IONIS, INCARE, ACESO. To know more about us visit our{" "}
            <a href="http://citst.ro" target="_blank" rel="noreferrer">
              website
            </a>
            .
          </div>
        </div>
      </div>
      <div className="row">
        <h2 id="projectRole">Project Role</h2>
      </div>

      <div className="row" id="projectRoleDetails">
        <div className="col">
          <p id="projectRoleIntroduction">
            CITST will design and implement games which shall aid in improving
            the physical and mental health. Also data analysis of collected
            parameters from games will be performed. CITST will contribute to
            development of the business model and the commercialization plan.
          </p>
        </div>
      </div>
      <div className="row">
        <h2 id="projectRelatedExpertise">Related Expertise </h2>
      </div>

      <div className="row" id="projectRelatedExpertiseDetails">
        <div className="col">
          <p id="projectRelatedExpertiseIntroduction">
            Hardware and software development, technology for health and
            wellbeing, robotic platforms, intelligent environments, senior
            usability.
          </p>
        </div>
      </div>

      <div className="row">
        <h2 id="teamMembers">Team Members</h2>
      </div>
      <div className="row">
        <div className="col" id="teamMemberDetails">
          <h5 id="teamMemberName">Irina Mocanu </h5>
          <p id="memberIntroduction">
            She is researcher at CITST and Professor at University Politehnica
            of Bucharest, Computer Science Department with focus machine
            learning, computer vision, data processing, computer games and
            applications of ambient intelligence.
          </p>
        </div>
      </div>

      <div className="row">
        <div className="col" id="teamMemberDetails">
          <h5 id="teamMemberName">Oana Cramariuc (CEO)</h5>
          <p id="memberIntroduction">
            She has extensive international experience in project management,
            data processing as well as service development, business planning
            and intellectual property and patents.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Citst;
