import "./Blogs.css";
import { NavLink } from "react-router-dom";
const Blog3 = () => {
  return (
    <div className="container  ">
      <div className="row blogs" id="main-title">
        <NavLink to="/blogs">
          <h1>Blogs</h1>
        </NavLink>
      </div>
      <div className="row full_blogs">
        <h4>Positive Health, Intrinsic Capacity, and CAREUP system</h4>
        <p class="card-text">
          <small className="text-muted">Published on 21 December 2022</small>
        </p>
        <p>
          Population ageing is a global trend. Older people who are facing this
          stage of life, especially those with physical and cognitive problems
          related to the progressive deterioration, may have some worries and
          insecurities related to the future. Older adults aged 65 years and
          more often complain of declining energy levels, even in the absence of
          a clinically evident disease (decreased capacity and loss of reserves
          and/or robustness) and face difficulties in finding meaning in life
          and setting goals. Therefore, they may need to be supported in
          managing their health and well-being.{" "}
        </p>
        <p>
          The CAREUP solution allows seniors to adhere to the concepts of
          Positive Health (PH) and Healthy Ageing (HA) by self-monitoring their
          key elements: Functional Ability (FA) and Intrinsic Capacity (IC). FA
          is made up of IC of the individuals, the environments in which they
          live and the interaction between the individual and these
          environments. Intrinsic capacity (IC) was defined as “the composite of
          all the physical and mental capacities of an individual”, including
          ability to walk, think, feel, interact, reflect, see, hear and
          remember. The World Health Organization (WHO) identified 5 IC core
          elements or domains: locomotion (physical capacity), vitality
          (nutrition, energy and balance), cognitive performance, psychological
          status, and sensory functioning (including vision and hearing). These
          domains influence each other and are in turn influenced by
          environmental factors. IC decline was significantly associated with
          increased risk of frailty, disability, falls, fractures and death.
        </p>
        <p>
          The CAREUP project aims to design and implement a platform based on
          information and communication technologies (ICT) able to monitor the
          functional ability and intrinsic capacity of individuals in relation
          to their specific environments in order to compensate for their
          declines due to aging through the development of a personalized
          assistance plan with multicomponent interventions: an integrated care
          approach to the person's entire lifespan that shifts the focus from
          disease care to optimizing a person’s IC across their life. The
          innovative CAREUP system collects data through: 1) Environmental
          measurements by sensors; 2) Questionnaires (online or paper); 3)
          Monitoring of physiological parameters (blood pressure, body weight,
          blood sugar, grip strength, body temperature, blood oxygen); 4)
          Cognitive games (associate and collect objects, detect differences,
          puzzles, numerical games, maze). The data collected and analyzed will
          be used to draw up a personalized care plan that will contain a list
          of useful suggestions to maintain a daily lifestyle oriented towards
          health and psychophysical well-being.
        </p>
        <p>
          The CAREUP system will display changes in the user's health status and
          compare them to self-defined health goals. This will generate
          awareness about the effects of the intervention on the user's health
          status. At the same time, the multidisciplinary care team (which will
          also include family members) will be able to monitor the health status
          of users and report changes that require intervention. This CAREUP
          reporting feature will reduce stress and worries of family members and
          support timely interventions, allowing older people to stay longer in
          their family environment.
        </p>
        <p>
          The CAREUP platform will be beneficial for all user groups involved:
          1) it will help primary users identify their goals, increase their
          health awareness and mobilize to be active; it will allow them to stay
          at home longer and reduce the chances of accidents requiring
          hospitalization and costly interventions; it will consequently improve
          their state of health; 2) it will enable more accurate and efficient
          diagnostics for formal secondary users; it will also support informal
          assistance from family members for a longer period of time and reduce
          the burden of it (time and effort required to install/monitor the
          platform and train end users to use it); (3) for tertiary users
          (stakeholders), it will reduce the burden on assistive systems and
          improve their efficiency; it will also reduce healthcare costs.
        </p>
      </div>
    </div>
  );
};

export default Blog3;
