import "./Profiles.css";
const Exys = () => {
  return (
    <div className="container">
      <div className="row" id="main-title">
        <h1>ECLEXYS</h1>
      </div>

      <div className="row" id="partner-intro">
        <div className="col">
          <p id="partnerInroduction">
            ECLEXYS is a Research & Development SME that is based in Riva San
            Vitale, Southern Switzerland with core business that is dedicated to
            the design and implementation of processing platforms, data
            communication, data security and localisation and tracking systems.
            The core mission of ECLEXYS is to exploit several branches of ICT
            and electronic engineering (cybersecurity, networking, satellite
            positioning, embedded systems) in mutual synergy, to establish a new
            paradigm in the provision of cost-effective healthcare and
            caregiving solutions. This goal is achieved by combining hardware
            and software expertise, while keeping a strong focus on ITC/AI
            innovation, performance, optimization and integration aspects.
            ECLEXYS makes sophisticated technology available as a beneficial,
            integral part of everyday life. To know more about us visit our{" "}
            <a href="http://www.eclexys.com" target="_blank" rel="noreferrer">
              website
            </a>
            .
          </p>
        </div>
      </div>
      <div className="row">
        <h2 id="projectRole">Project Role</h2>
      </div>

      <div className="row" id="projectRoleDetails">
        <div className="col">
          <p id="projectRoleIntroduction">
            EXYS is the co-ordinating partner that will ensure a successful
            management of CAREUP and an efficient coordination of its tasks to
            reach its planned objectives. EXYS is also leading some work
            packages and deliverables in the project.
          </p>
        </div>
      </div>
      <div className="row">
        <h2 id="projectRelatedExpertise">Related Expertise </h2>
      </div>

      <div className="row" id="projectRelatedExpertiseDetails">
        <div className="col">
          <p id="projectRelatedExpertiseIntroduction">
            EXYS combines its activity in Swiss and international industrial
            projects with research and development involvement in many European
            Commission (EC) FP6, FP7 and H2020 projects, with the role of
            technology partner or Project Coordinator (8 EC projects were
            coordinated by ECLEXYS). ECLEXYS has developed the EXYS ALSS
            (ambient living support system), a platform based on proprietary
            technologies, designed to make extremely simple and cheap the
            provision of health and care services. The EXYS ALSS will be
            exploited for the Home/Health Monitoring Platform.
          </p>
        </div>
      </div>

      <div className="row">
        <h2 id="teamMembers">Team Members</h2>
      </div>
      <div className="row">
        <div className="col" id="teamMemberDetails">
          <h5 id="teamMemberName">Angelo Consoli</h5>
          <p id="memberIntroduction">
            Founder and managing director of ECLEXYS Sagl. Professor for data
            communication, ICT security and cyber threat intelligence (CTI) at
            the University of Applied Sciences of Southern Switzerland (SUPSI),
            responsible for the module “new communication technologies” of the
            Swiss Master of Science in Engineering (MSE). Project Coordinator
            for many European Commission (EC) projects such as: GRABEL
            FP7-SME-2008-1, NITICS FP7-AAL-2012-5, GINSEC FP7-SME- 2013-1,
            NISTAS FP7-SME-2013-1, IONIS H2020-AAL-2017.
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col" id="teamMemberDetails">
          <h5 id="teamMemberName">Dr. Jaouhar Ayadi</h5>
          <p id="memberIntroduction">
            MSc’95 and Ph.D.’99 in Wireless Communication from the Ecole
            Nationale Supérieure des Télécommunications (ENST), Paris, France.
            He joined the Wireless Communication Section of the “Centre Suisse
            d’Electronique et de Microtechnique (CSEM)” as an R&D Engineer, than
            a Senior R&D Engineer and Project Manager. He was the CSEM’s Project
            Manager of many European Commission (EC) projects in the Information
            and Communications Technologies (ICT) domain, belonging to the EC
            framework programmes FP5, FP6 and FP7, including: IST-PACWOMAN,
            ICT-MAGNET, ICT-MAGNET-BEYOND, ICT-CRUISE.
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col" id="teamMemberDetails">
          <h5 id="teamMemberName">Ing. Luca Gilardi</h5>
          <p id="memberIntroduction">
            BSc. In Computer Science at the University of Applied Science of
            Southern Switzerland (SUPSI), Lugano in 1991. Active as senior
            software engineer and system administrator. Several years of field
            experience working for private companies in the domains of
            automotive industry, clinical trials and tests, pharmacology and
            medical devices manufacturing, microbiology research area, as well
            as in software engineering and development organizations. Experience
            as quality assurance responsible in three ISO 9000 certifications;
            involved in one GMP/GLP (good manufacturing practices/good
            laboratory practices) standards certification.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Exys;
