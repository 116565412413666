import "./Profiles.css";
const Eurag = () => {
  return (
    <div className="container">
      <div className="row" id="main-title">
        <h1>EURAG Österreich</h1>
      </div>
      <div className="row" id="partner-intro">
        <div className="col">
          <div id="partnerInroduction">
            <p>
              EURAG Austria, the local platform of the umbrella organization
              EURAG Europe, is a seniors organisation that has gained extensive
              experience in European projects that aim at combating age
              discrimination and promoting the physical and mental health of the
              older population to ensure quality of life in old age. EURAG
              collaborates closely with universities and care institutions,
              federal and local governments and the media. Through the Academic
              Advisory Board EURAG Austria has access to research and
              development of new strategies and technologies. As member of the
              General Council, EURAG Austria can recourse to an extensive
              network of EURAG organizations in European countries and beyond,
              as well as to member organizations. To know more about us visit
              our{" "}
              <a href="https://eurag.at/" target="_blank" rel="noreferrer">
                website
              </a>
              .
              <br />
              EURAG is a non-political, non-confessional and non-profit
              organization.
            </p>
            <p>The main focus of EURAG Austria lies in </p>
            <ul>
              <li>
                Improving life quality, well being, self determination and
                independency of senior citizens with the help of new
                technologies
              </li>
              <li>Maintaining solidarity between the generations</li>
              <li>Empowering the older generation</li>
              <li>Preventing discrimination and exclusion of older people</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="row">
        <h2 id="projectRole">Project Role</h2>
      </div>

      <div className="row" id="projectRoleDetails">
        <div className="col">
          <p id="projectRoleIntroduction">
            EURAG Austria will act as an enduser organisation within CAREUP and
            is responsible for the iterative involvement of the target group of
            older people in all various project phases, starting with surveying
            the needs of the older generation and translating them into
            requirements for the CAREUP system as well as obtaining feedback and
            testing initial prototypes.
          </p>
        </div>
      </div>
      <div className="row">
        <h2 id="projectRelatedExpertise">Related Expertise </h2>
      </div>

      <div className="row" id="projectRelatedExpertiseDetails">
        <div className="col">
          <p id="projectRelatedExpertiseIntroduction">
            EURAG Austria has been involved in various research projects related
            to the needs of the elderly over the last 10 years, including a
            number of AAL projects as well as in a H2020 project.
          </p>
        </div>
      </div>

      <div className="row">
        <h2 id="teamMembers">Team Members</h2>
      </div>
      <div className="row">
        <div className="col" id="teamMemberDetails">
          <h5 id="teamMemberName">Mag. Eva Reithner</h5>
          <p id="memberIntroduction">
            Holds a Master degree in communication and has been working for more
            than 10 years for EURAG Austria where she gained extensive
            experience in coordination and execution of EU projects concerning
            the topic of older adults. She has worked in a wide variety of
            projects, both at national and EU level, all aimed at improving the
            quality of life of older adults. This ranges from lecture series to
            raise awareness on specific topics, organisation of large scale
            health days as well as the management of numerous EU projects (AAL,
            H2020, Erasmus,...).
          </p>
        </div>
      </div>
    </div>
  );
};

export default Eurag;
