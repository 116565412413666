/* eslint-disable jsx-a11y/anchor-is-valid */
import "./Navbar.css";
import Logo from "../../static/img/careup.png";
import { NavLink } from "react-router-dom";
const NavBar = () => {
  let style = { color: "red" };
  const Active = ({ isActive }) => {
    return {
      textDecoration: isActive ? "underline" : "none",
    };
  };
  return (
    <header className="menu_bar">
      <div className="under_menu"></div>

      <nav className="navbar navbar-expand-lg container ">
        <div className="container-fluid">
          <NavLink className="navbar-brand " to="/">
            <img src={Logo} alt="Logo" />
          </NavLink>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNavDropdown"
            aria-controls="navbarNavDropdown"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNavDropdown">
            <ul className="navbar-nav">
              {/* <li className="nav-item">
                <NavLink
                  className="nav-link "
                  aria-current="page"
                  to="challenges"
                  style={Active}
                >
                  Challenges
                </NavLink>
              </li> */}

              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDropdownMenuLink"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Project
                </a>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="navbarDropdownMenuLink"
                >
                  <li>
                    <NavLink className="dropdown-item" to="/consortium">
                      Consortium
                    </NavLink>
                  </li>
                  <li>
                    <NavLink className="dropdown-item" to="/work-packages">
                      Work Packages
                    </NavLink>
                  </li>
                  {/* <li>
                    <NavLink className="dropdown-item" to="/outcomes">
                      Outcomes
                    </NavLink>
                  </li> */}
                </ul>
              </li>

              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDropdownMenuLink"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Resources
                </a>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="navbarDropdownMenuLink"
                >
                  <li>
                    <NavLink className="dropdown-item" to="/press">
                      Press
                    </NavLink>
                  </li>
                  <li>
                    <NavLink className="dropdown-item" to="/leaflets">
                      Leaflets and Posters
                    </NavLink>
                  </li>
                  <li>
                    <NavLink className="dropdown-item" to="/publications">
                      Publications, presentations, videos
                    </NavLink>
                  </li>
                  <li>
                    <NavLink className="dropdown-item" to="/blogs">
                      Blogs
                    </NavLink>
                  </li>
                </ul>
              </li>

              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDropdownMenuLink"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Events & News
                </a>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="navbarDropdownMenuLink"
                >
                  <li>
                    <NavLink className="dropdown-item" to="/events">
                      Events
                    </NavLink>
                  </li>
                  <li>
                    <NavLink className="dropdown-item" to="/news">
                      News
                    </NavLink>
                  </li>
                </ul>
              </li>
              <li className="nav-item">
                <NavLink
                  className="nav-link "
                  aria-current="page"
                  to="contact"
                  style={Active}
                >
                  Contact
                </NavLink>
              </li>
              <li className=" nav-item">
                <a
                  href="https://www.linkedin.com/in/careup-project-890156243/"
                  className=" nav-link"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fab fa-linkedin" id="linkedin"></i>
                </a>
              </li>
              <li className="nav-item">
                <a
                  href="https://twitter.com/CAREUP2022"
                  className=" nav-link"
                  target="_blank"
                  rel="noreferrer"
                >
                  {/* <i className="solid fa-x" id="twitter"></i>
                  <i class="fa-solid fa-x"></i> */}
                  <i class="fab fa-x-twitter"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default NavBar;
