import "./Contacts.css";
const Contact = () => {
  return (
    <div className="container">
      <div className="row" id="main-title">
        <h1>Contact</h1>
      </div>
      <div className="row">
        <div className="col contact">
          <h1>Contact</h1>
          <p>
            ECLEXYS SAGL <br />
            Via dell'Inglese 6 <br />
            CH-6826 Riva San Vitale <br />
            Switzerland <br />
          </p>
          <p>Email:office[at]eclexys.com</p>
        </div>
      </div>
    </div>
  );
};

export default Contact;
