import "./Blogs.css";
import { NavLink } from "react-router-dom";
const Blog2 = () => {
  return (
    <div className="container ">
      <div className="row blogs" id="main-title">
        <NavLink to="/blogs">
          <h1>Blogs</h1>
        </NavLink>
      </div>
      <div className="row full_blogs">
        <h4>
          Usage of technical solutions in daily life – how to deal with privacy,
          confidentiality and data protection
        </h4>
        <p class="card-text">
          <small className="text-muted">Published on 03 November 2022</small>
        </p>
        <p>
          In view of demographic change and the competition for employees in the
          care sector, technical solutions will become increasingly important in
          the future. On the one hand, these should focus on improvement of the
          quality of life of older adults as well as taking a burden off the
          shoulders of caregivers.
        </p>
        <p>
          With the use of such technology solutions, which collect more and more
          data to achieve the best possible result for the user, the question of
          privacy, confidentiality and data protection arises.
        </p>
        <p>
          From an ethical point of view, the development of such technology
          solutions for older adults must take into account how the privacy of
          individuals can be granted.
        </p>
        <p>
          Privacy consists of various aspects. For the type of technology being
          developed in the CAREUP project, the handling of personal health data
          is particularly relevant. Here, it is crucial that users have the
          right to decide for themselves on the use of personal data and can
          thus preserve their physical, social and mental intimacy.
          Nevertheless, it must always be weighed up what makes more sense: the
          preservation of privacy or the security aspect. If, for example,
          health data is collected and forwarded with the help of a monitoring
          system, this may be an invasion of privacy, but it also means that
          help can be called as quickly as possible in case of emergency.
        </p>
        <p>
          However, it is essential that all collected data, its forwarding and
          storage are secured against access by unauthorized third parties and
          against misuse by means of special protective measures. Users must be
          given the confidence that their data is well protected and cannot be
          used by anyone without authorization. If data is to be forwarded to
          relatives, caregivers, medical personnel, etc., this must be agreed
          with the users, who should decide for themselves who should have
          access.
        </p>
        <h5>
          In summary, the following points should be considered when addressing
          user privacy (Leikas & Koivisto, 2015)
        </h5>
        <ul>
          <li>
            Only truly essential information about users should be collected.
          </li>
          <li>
            Users should be able to easily confirm the collected information
            about themselves in a simple way.
          </li>
          <li>
            The information collected for a specific purpose should not be used
            for any other purpose without the consent of the user.{" "}
          </li>
          <li>All information collected should be considered private data.</li>
          <li>
            {" "}
            Users should be informed about the content and the scope of the data
            collected during the use of the service, as well as its purpose.{" "}
          </li>
          <li>
            It should be easy for users to withdraw consent to the collection
            and use of data.{" "}
          </li>
          <li>
            Where possible, appropriate anonymization strategies should be used.
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Blog2;
