const Outcomes = () => {
  return (
    <div className="container">
      <div className="row" id="main-title">
        <h1>Outcomes</h1>
      </div>
    </div>
  );
};

export default Outcomes;
