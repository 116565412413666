import { NavLink } from "react-router-dom";
import "./Blogs.css";
import art19_image from "../../../static/img/art19.png";

const Blog19 = () => {
  return (    
      <div className="container ">
        <div className="row blogs" id="main-title">
          <NavLink to="/blogs">
            <h1>Blogs</h1>
          </NavLink>
        </div>
        <div className="row full_blogs">
          <h4>
            Gait analysis – what, why and how?
          </h4>
          <p class="card-text">
            <small className="text-muted">Published on 13 May 2024</small>
          </p>          
          <p>
            As nowadays we live in the quickly ageing society, a need for the novel methods of the quick health 
            and wellbeing assessment of the older adults is emerging. Of course, there is a variety of standard 
            health evaluation methods such as	blood pressure and oxidation, temperature etc. measurements or 
            examinations by the GPs. However, such methods may be either insufficient as they do not provide 
            enough information regarding some of the illnesses or may be inefficient regarding the potential 
            number of the patients that can be served.
          </p>          
          <p>
            Gait is something that we tend to take for granted. As long as everything is fine, there is no need 
            to analyze it or even dive deeper into the general characteristics of it to actually understand how 
            it works and what it is related to. Basically, gait is directly connected to the person’s ability to 
            be independent and to function normally on the daily basis. It defines the mobility and influences 
            everyday quality of life, by implicitly affecting such activities as walking, standing, sitting, and 
            getting up, turning etc. All of this allows for maintaining the independent lifestyle. As such, any 
            gait disorders may serve as marker of the general health and an early alarm bell to predict incoming 
            health deterioration and possible ill conditions.
          </p>
          <p>
            Gait itself is quite complex. It involves cardiorespiratory, nervous, and musculoskeletal systems of 
            the human body, all working together in a perfect harmony. Synchronization and interoperability of 
            those various systems is crucial to maintaining gait parameters withing their proper limits and to 
            allow person to function normally.
          </p>
          <p>
            Each person has their own, unique gait pattern that can be analyzed and determined. This pattern may 
            be affected over time by the person’s physical and mental state, by mechanical injuries or social 
            factors. Of course, it is normal, that ageing also affects our gait or at least some parameters that 
            are used to describe it.
          </p>
          <p>
            Gait can be visualized using so called gait-cycle, as shown in the picture below [1].
          </p>          
            <img src={art19_image} className="agtech" alt="..." />
            <p align="center">( source: An Introduction to Gait Analysis - Types & Applications of gait analysis (auptimo.com)
            &nbsp;<a href="https://auptimo.com/an-introduction-to-gait-analysis/" target="_blank" rel="noreferrer">
            https://auptimo.com/an-introduction-to-gait-analysis</a> )</p>                    
          <p></p>
          <p>
            It is divided into two main phases, stance (around 60% of the gait cycle), and swing (around 40% of 
            the gait cycle), consisting of either double limb (double stance) support or single limb support. 
            Following are the main gait characteristics:
          </p>     
          <p>
            <ul>
              <li>
                Cadence – defined as the number of steps that person does over a minute. It is purely 
                personal, depends on the person’s height, stride length and overall walking comfort. 
                Usually, does not change over time.             
              </li>
              <li>
                Step length – distance measured between the heel prints of the two feet, tends to get shorter with age.
              </li>            
              <li>
                Gait velocity – speed of walking (time to walk a specified distance), remains rather constant over 
                most of one’s lifespan, however, tends to decline around the age of 65-70. The decline is mainly due 
                to the steps getting shorter. There is a relation between the gait velocity’s decline and the 
                person’s mortality – people how walk faster, tend to live longer.
              </li>
              <li>
                Double limb (stance) time – time, during which both legs are on the surface. In most cases, this time 
                takes around 25% of the gait cycle. May change with age (namely, gets longer).
              </li>
            </ul>
          </p>
          <p>
            By no means this list is exhaustive, there are other parameters, such as walking posture, joint motion, or 
            balance, which are of high importance for the determination of the physical health but are harder to 
            describe and quantify.
          </p>
          <p>
          There is a variety of methods to measure and analyze gait. They can be divided into two categories: 
          </p>
          <p>
            <ol>
              <li>
                Quantitative, that gives detailed and objective data regarding the gait parameters:
                <ul className="ul-disc">
                  <li>
                    Kinematic analysis, can be either 2D or 3D, done using the special markers attached to the person on 
                    the various crucial places on the body, digital cameras, possibly treadmill and/or force plates and 
                    motion analysis software. It is done mainly in the specialized gait labs.
                  </li>
                  <li>
                    Kinetic analysis
                    <ul>
                      <li>
                        Using force and pressure plates, done in line with the kinematic analysis.
                      </li>
                      <li>
                        IMU (Inertial Measurement Units) -based, done using the gyroscopes and accelerometers. May be cheaper 
                        than other methods and can be done on daily basis, outside of the lab, without the need for expensive 
                        equipment.
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li>
                Qualitative, does not provide numerical data per se, but allows to determine the general state of the 
                person:
                <ul>
                  <li>
                    Visual observation.
                  </li>
                  <li>
                    Observation using cameras.
                  </li>
                  <li>
                    Questionnaires and medical interviews.
                  </li>
                </ul>
              </li>
            </ol>
          </p>

          <p></p>
          <div id="article-references">
            <h5><i>References:</i></h5>            
              <li>
              <a href="https://auptimo.com/an-introduction-to-gait-analysis/" target="_blank" rel="noreferrer">
                An Introduction to Gait Analysis - Types & Applications of gait analysis (auptimo.com)
              </a>
              </li>             
              <li>               
                <a href="https://www.physio-pedia.com/Gait" target="_blank" rel="noreferrer">
                  Gait - Physiopedia (physio-pedia.com)
                </a>                
              </li>              
              <li>
                <a href="https://www.msdmanuals.com/professional/geriatrics/gait-disorders-in-older-adults/gait-disorders-in-older-adults#Treatment_v1137191" target="_blank" rel="noreferrer">
                  Gait Disorders in Older Adults - Geriatrics - MSD Manual Professional Edition (msdmanuals.com)
                </a>                
              </li>              
            <p></p>
          </div>         
        </div>           
        <script>window.scrollTo(0,0);</script>
      </div>
      
      
  );
};

export default Blog19;
