import "./Press.css";
import { NavLink, Link } from "react-router-dom";

const Press = () => {
  return (
    <div className="container">
      <div className="row" id="main-title">
        <h1>Press Release</h1>
      </div>
      <div className="row languages">
        <h6>
          <button id="IT">
            <NavLink to="/press-fr">FR</NavLink>
          </button>
          |
          <button>
            <NavLink to="/press-de">DE</NavLink>
          </button>
          |
          <button>
            <NavLink to="/press-pol">POL</NavLink>
          </button>
          |
          <button>
            <NavLink to="/press-it">IT</NavLink>
          </button>
          |
          <button>
            <NavLink to="/press-ro">RO</NavLink>
          </button>
        </h6>
      </div>
      <div className="row" id="press">
        <h1>
          Integrated Care Platform Based on the Monitoring of Older Individual
          Intrinsic Capacity for Inclusive Health (CAREUP)
        </h1>
        <h6>Press release/ 30 June 2022</h6>
        <p>
          The CAREUP project, coordinated by ECLEXYS Sagl, the project
          investigates how to implement the “inclusive health & care approach
          (health prevention, preservation of physical & mental health, social
          participation)” of the AAL Call 2021 by taking into account the
          specific needs of end-users. Primary end-users, older adults, aged
          65+, people often complain of declining energy levels, even in the
          absence of a clinically overt disease (declining capacity and the loss
          of reserves or robustness) and face difficulty in finding sense of
          purpose in life and difficulty with goals setting. We argue that such
          aspects are due to lack a proper life-coach approach. The CAREUP
          project proposes an ICT-based solution to continuously enhance the
          quality of life of older adults following a life-course framework and
          using the guidance of the ‘Positive Heath’ (PH) concept and the WHO
          ‘Healthy Ageing’ (HA) one. The CAREUP solution allows older adults to
          adhere to PH/HA by self-monitoring its key elements: Functional
          Ability (FA)and Intrinsic Capacity (IC). The FA comprises the IC of
          the individual and includes all the mental, social, cognitive, and
          physical capacities that a person can draw on and includes their
          ability to walk, think, feel, interact, reflect, see, hear and
          remember.
        </p>
        <br />
        <p>
          To achieve the above mentioned, the project proposes a
          person-centered and integrated approach to design and implement an ICT
          platform that is capable of monitoring the IC and FA of individuals in
          relation with their specific environments in order to: compensate for
          their declines due to ageing through development of a customized and
          personalized care plan with multicomponent interventions. The CAREUP
          platform requires almost no actions from the user. Human interaction
          is combined with IT technology.{" "}
        </p>
        <br />
        <p>
          The platform is built at low costs and is open for extensions with new
          sensors, questionnaires and games. For the secondary end-users(formal
          and informal caregivers), the main need that CAREUP will assess for
          them is theproposition of the right/global care provision model
          leading toan integrated care approachto person’s whole-life-course
          that shifts the focus from curing disease to optimizing a person’s IC
          across their life. The main issue for the secondary end-users will be
          the time and effort it takes to install and maintain the platform and
          train the end-users to use it. To do so the platform is co-created
          together with primary and secondary users, informal and formal ones
          (doctors, social care workers, psychologists...) to realize our unique
          selling proposition. For tertiary end-users (stakeholders), the CAREUP
          solution will fulfil their need offostering a healthy aging ecosystem
          including affordable health care and healthy lifestyle business
          opportunities and also the cost-effectiveness of the proposed
          solutions for it.
        </p>
        <p>
          Finally, the CAREUP project <Link to="/consortium">consortium partners</Link> is well-balanced both in terms 
          of geographical spread (9 partners from 5 countries) and types: the consortium consists of three categories
          of partners, one universities and R&D Centre, one end user and R&D,
          two end-user and five SMEs.
        </p>
        <p>
          For additional information please visit (<Link to="/">CAREUP</Link>)
        </p>
      </div>
    </div>
  );
};

export default Press;
