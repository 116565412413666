import { NavLink } from "react-router-dom";
import "./Blogs.css";
import art25_1_image from "../../../static/img/art25_1.jpg";
import art25_2_image from "../../../static/img/art25_2.jpg";
import art25_3_image from "../../../static/img/art25_3.jpg";
import art25_4_image from "../../../static/img/art25_4.jpg";

const Blog25 = () => {
  return (    
      <div className="container ">
        <div className="row blogs" id="main-title">
          <NavLink to="/blogs">
            <h1>Blogs</h1>
          </NavLink>
        </div>
        <div className="row full_blogs">
          <h4>
            The importance of physical activity in the older population
          </h4>
          <p class="card-text">
            <small className="text-muted">Published on 6 November 2024</small>
          </p>
          <img src={art25_1_image} className="agtech" alt="..." /> <img src={art25_2_image} className="agtech" alt="..." />           
          <p>
          The older population is increasing worldwide, and it is estimated that it may even double by 2060 (US Dept of Commerce, 2014). 
          Older people are defined as frail individuals as more vulnerable than others and, therefore, more exposed to adverse 
          health outcomes (Clegg A. et al.; 2013), and, in the long term, often result in loss of functional independence and decreased quality of life.
          <br />                                                        
          The public health system proposes physical activity to prevent such negative health consequences (Fried LP et al.; 2001). 
          Higher levels of physical activity appear to be associated with better health and cognitive function in older adults (HollmannW et al.; 2007). 
          There is evidence that the incidence of chronic diseases is higher in those who do not engage in physical activity (Bielemann RM et al.; 2015). 
          Consequently, physical inactivity with advancing age predisposes more easily to disabling chronic diseases, from atherosclerosis to myocardial 
          insufficiency, coronary artery disease, hypertension, stroke and diabetes (Gill T.M et al; 2004). 
          </p>
          <p></p>
          <img src={art25_3_image} className="agtech" alt="..." />   
          <p>
            Physical activity is, therefore, necessary for frail older individuals and effective as a preventive measure when performed regularly 
            (Kunutsor SK et al., 2021). With ageing, several biological systems undergo deterioration. Consequently, older people must perform sufficient 
            daily activities to compensate for these physiological deficiencies (O'Grady M et al.; 2000). Regular physical activity improves physical and 
            mental functions, attenuates some effects of chronic diseases and ensures motor autonomy and independence (McPhee JS et al.; 2016). 
            Regular exercise promotes what is defined as "healthy ageing" (Hoglund M.W et al.; 2009), characterised by high physical, psychological and social 
            functioning and the absence of serious diseases (Kim HJ et al., 2019).
          </p>
          <p></p>
          <img src={art25_4_image} className="agtech" alt="..." />          
          <p>
            Physical activity can be defined as any bodily movement (of skeletal muscles) that requires energy expenditure, from specific exercise (intentional 
            physical activity) to habitual occupational/domestic activity (Bull FC et al., 2020). Exercise, in detail, can be classified by type into 
            Aerobic Activity (or endurance or cardio), Strengthening Activity, Balance and Multi-component Physical Activity. Classifying by intensity gradient, 
            we can discern between absolute, moderate, and relative intensity exercises. <br />
            For older people, multi-component physical activity is preferable, such as aerobic, muscle strengthening, and balance exercises. 
            Improving balance and strength is particularly important in reducing the risk of falls (Piercy KL et al., 2018). Regarding intensity, moderate and 
            relative intensity exercises are preferred. Since aerobic capacity decreases in older people, it is good to determine their level of effort from 
            time to time and adjust exercise intensity accordingly (American College of Sports Medicine, 2006). <br />
            Following these indications, the most recommended activity for the older people is walking. Defined as low to moderate-intensity physical activity, 
            walking improves aerobic fitness and facilitates a decrease in body weight. (Lee LL et al.; 2021). As a rule, the faster the walk, the better 
            the cardiovascular health, but generally, any walking at any pace is recommended. The current guidelines, for example, suggest walking 30 min a day 
            for 5 days (Bull FC et al, 2020). Other recommended activities range from specific exercise programs, such as gentle gymnastics, to cycling or 
            various sports, such as table tennis, tai chi or dancing (Piercy KL et al., 2018). It has also been shown that these exercises, being specific and 
            appropriate to elderly needs, are very satisfying for them. (Jung YH et al; 2023). <br />
            Promoting physical activity for older adults is therefore possible and necessary, as well as highly satisfying!
          </p>
          <p></p>
          <div id="article-references">
            <i>
            <h5><i>References</i></h5>            
              <li>
                U.S. Department of Commerce. US Census Bureau Projections Show a Slower Growing, Older, More Diverse Nation a Half Century from Now. 
                United States Census Bureau; 2014. [cited 2014 Mar 1]; Available from: &nbsp;
                <a href="http://www.census.gov/newsroom/releases/archives/ " target="_blank" rel="noreferrer">
                  http://www.census.gov/newsroom/releases/archives/ 
                </a>.
              </li>
              <li>
                Clegg A, Young J, Iliffe S, Rikkert MO, Rockwood K. Frailty in elderly people. Lancet. 2013 Mar 2; 381(9868):752–62.
              </li>
              <li>
                Fried LP, Tangen CM, Walston J, Newman AB, Hirsch C, Gottdiener J, et al. Frailty in older adults: evidence for a phenotype. J Gerontol A Biol Sci Med Sci. 2001 Mar; 56(3):M146–M156. [PubMed: 11253156]
              </li>
              <li>
                HollmannW, StrüderHK, Tagarakis CV, KingG. Physical activity and the elderly. Eur J Prev Cardiol. (2007) 14:730–9. doi: 10.1097/HJR.0b013e32828622f9
              </li>
              <li>
                Bielemann RM, Silva BGCD, Coll CDVN, Xavier MO, Silva SGD. Burden of physical inactivity and hospitalization costs due to chronic diseases. Rev Saude Publica. (2015) 49:75. doi: 10.1590/S0034-8910.2015049005650 
              </li>
              <li>
                Gill T.M, Allore H, Guo Z. The deleterious effects of bed rest among community-living older persons. J Gerontol A Biol Sci Med Sci. (2004) 59:M755 61. doi: 10.1093/gerona/59.7.M755 
              </li>
              <li>
                Kunutsor SK, Seidu S, Makikallio TH, Dey RS, Lauk kanen JA. Physical activity and risk of atrial fibrillation in the general population: meta-analysis of 23 cohort Vol:(1234567890) studies involving about 2 million participants. Eur J Epi demiol. 2021; 36:259–74.
              </li>
              <li>
                O’Grady M, Fletcher J, Ortiz S. Therapeutic and physical fitness exercise prescription for older adults with joint disease: an evidence-based approach. Rheum Dis Clin N Am. (2000) 26:617–46. doi: 10.1016/S0889-857X(05)70159-9 
              </li>
              <li>
                McPhee JS, French DP, Jackson D, Nazroo J, Pendleton N, Degens H. Physical activity in older age: perspectives for healthy ageing and frailty. Biogerontology. (2016) 17:567–80. doi: 10.1007/s10522-016-9641-0 
              </li>
              <li>
                Hoglund M.W, Sadovsky R, Classie J. Engagement in life activities promotes healthy aging in men. J Mens Health. (2009) 6:354 365. doi: 10.1016/j.jomh.2009.09.032
              </li>
              <li>
                Kim HJ, Min JY, Min KB. Successful aging and mortality risk: the Korean longitudinal study of aging (2006–2014). J Am Med Dir Assoc. (2019) 20:1013 20. doi: 10.1016/j.jamda.2018.12.010
              </li>
              <li>
                Bull FC, Al-Ansari SS, Biddle S, Borodulin K, Buman MP, Cardon G, Carty C, Chaput JP, Chastin S, Chou R, Dempsey PC, DiPietro L, Ekelund U, Firth J, Frieden reich CM, Garcia L, Gichu M, Jago R, Katzmarzyk PT, et al. World Health Organization 2020 guidelines on physical activity and sedentary behaviour. Br J Sports Med. 2020; 54:1451–62.
              </li>
              <li>
                Piercy KL, Troiano RP, Ballard RM, Carlson SA, Fulton JE, Galuska DA, George SM, Olson RD. The physical activity guidelines for Americans. JAMA. 2018; 320:2020–8.
              </li>
              <li>
                American College of Sports Medicine. ACSM’s Guidelines for Exercise Testing and Prescription. 7th ed. Baltimore, MD: Lippincott Williams & Wilkins; 2006
              </li>
              <li>
                Lee LL, Mulvaney CA, Wong YKY, Chan ES, Watson MC, Lin HH. Walking for hypertension. Cochrane Data base Syst Rev. 2021;2:CD008823.
              </li>
              <li>
                Jung, Y. H., Park, J. B., Kang, A., & Cho, K. C. (2023). The elderly's satisfaction with physical activity programs in senior welfare centers. Frontiers in Public Health, 11, 1170612.
              </li>
              </i>
            </div>
          
          <p></p>
        </div>           
        
        <script>window.scrollTo(0,0);</script>
      </div>
            
  );
};

export default Blog25;
