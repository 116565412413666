import "./Profiles.css";
const Wut = () => {
  return (
    <div className="container">
      <div className="row" id="main-title">
        <h1>Warsaw University of Technology</h1>
      </div>
      <div className="row" id="partner-intro">
        <div className="col">
          <p id="partnerInroduction">
            Warsaw University of Technology is one of the largest universities
            in Eastern Europe. The project activities will be carried out by the
            research teams from the Institute of Radioelectronics and Multimedia
            Technology. The research interests of team members cover IoT
            networks, localization systems, ultra-wideband technology,
            radiocommunications, machine learning, deep neural networks. The
            team has successfully completed several projects for national
            agencies and industrial partners: e.g. National Radiocommunication
            Agency, Mostostal Warszawa S.A. The activities performed within the
            projects covered the full process of systems design: development of
            prototypes and software controlling system operation. The team
            developed radio positioning systems within the NITICS, IONIS and
            INCARE AAL projects. To know more about us visit our{" "}
            <a href="https://eng.pw.edu.pl" target="_blank" rel="noreferrer">
              website
            </a>
          </p>
        </div>
      </div>
      <div className="row">
        <h2 id="projectRole">Project Role</h2>
      </div>

      <div className="row" id="projectRoleDetails">
        <div className="col">
          <p id="projectRoleIntroduction">
            Hardware and software integration, gait investigation, activity
            monitoring with positioning systems, end-user partners support. AI
            engine, machine learning and data analytics.
          </p>
        </div>
      </div>
      <div className="row">
        <h2 id="projectRelatedExpertise">Related Expertise </h2>
      </div>

      <div className="row" id="projectRelatedExpertiseDetails">
        <div className="col">
          <p id="projectRelatedExpertiseIntroduction">
            IoT networks, hardware and software development, intelligent
            sensors, positioning systems, localization algorithms
          </p>
        </div>
      </div>

      <div className="row">
        <h2 id="teamMembers">Team Members</h2>
      </div>
      <div className="row">
        <div className="col" id="teamMemberDetails">
          <h5 id="teamMemberName">Dr. Jerzy Kołakowski</h5>
          <p id="memberIntroduction">
            Dr. Jerzy Kołakowski has a PhD in telecommunications from the Warsaw
            University of Technology. Since 1988 he has been with the Institute
            of Radioelectronics and Multimedia Technology. His current research
            interests include IoT technologies, positioning systems, ultra
            wideband technology, cellular systems. At present he is a head of
            the IoT Systems Group. He took part in several projects on
            radiocommunication equipment testing, electromagnetic compatibility,
            spectrum monitoring, direction finding and UWB technology. Co‐author
            of the book on UMTS. Leader of the team involved in AAL projects
            NITICS, IONIS, INCARE. The AAL solutions developed within the
            projects are mainly intended for persons tracking, localization of
            items, fall detection, collecting of data from wearable sensors. He
            is a member of the Management Board of the Foundation for the
            Development of Radiocommunications and Multimedia Technology.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Wut;
