import image from "../../static/img/background2.jpg";
import "./Home.css";
const Home = () => {
  return (
    <div className="container home">
      <div className="row row1">
        <h1>
          An Integrated Care Platform Based on the Monitoring of Older
          Individual Intrinsic Capacity for Inclusive Health (CAREUP)
        </h1>

        <p>
          To continuously enhance the quality of life of older adults following
          a life-course framework and using the guidance of the ‘Positive
          Health’ (PH) concept and the WHO ‘Healthy Ageing’ (HA) one.
        </p>
      </div>
      <div className="row row2">
        <h1 id="vision">Vision</h1>

        <img src={image} />
        <p>
          Our vision is to help people to learn how to take care of their own
          health through choosing a healthy lifestyle, reduce stress, connecting
          with like-minded people and conduct various activities like health
          activities, events, restaurants, holidays and more
        </p>
      </div>

      <div className="row row3">
        <div className="col-12">
          <h1 id="objectives">Objectives</h1>
        </div>
        <div className="col">
          <p>Support seniors to prolong their healthy life</p>
        </div>
        <div className="col">
          <p>Reduce stress and burden for caregivers</p>
        </div>
        <div className="col">
          <p>Develop an ICT integrated care platform</p>
        </div>
        <div className="col">
          <p>Ensure user satisfaction</p>
        </div>
        <div className="col">
          <p>Identify new business approaches</p>
        </div>
        <div className="col">
          <p>Develop a viable and innovative business plan</p>
        </div>
      </div>
      <div className="row row4">
        <div className="col-12">
          <h1 id="challenges">Challenges</h1>
        </div>
        <div className="col">
          <p>Difficulty with older adult capability assessment</p>
        </div>

        <div className="col">
          <p>Finding the right/global care provision</p>
        </div>
        <div className="col">
          <p>Burden and stress decrease</p>
        </div>
        <div className="col">
          <p>Cost effective care and sustainable solution</p>
        </div>
        <div className="col">
          <p>
            Difficulty in finding sense of purpose in life and difficulty with
            goals setting
          </p>
        </div>
      </div>
    </div>
  );
};

export default Home;
