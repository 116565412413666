import { NavLink } from "react-router-dom";
import "./Blogs.css";
const Blog6 = () => {
  return (    
      <div className="container  ">
        <div className="row blogs" id="main-title">
          <NavLink to="/blogs">
            <h1>Blogs</h1>
          </NavLink>
        </div>
        <div className="row full_blogs">
          <h4>Serious games for seniors</h4>
          <p class="card-text">
            <small className="text-muted">Published on 25 April 2023</small>
          </p>
          <p>
            One of the biggest challenges we face today is that, in the European
            Union alone, approximately 7,853,700 people are living with dementia
            (Alzheimer-Europe.org, 2020). The most common cause of dementia,
            Alzheimer's disease, can severely hamper the daily lives of seniors,
            preventing them from participating in social activities, carrying
            out routines, and taking care of themselves. Numerous studies and
            clinical research have been carried out with the aim of reducing
            this condition, but a satisfactory solution has not yet been
            reached.
          </p>
          <p>
            According to MIT Professor Li-Huei Tsai, a neuroscientist who
            focuses on diseases like Alzheimer's, leading a healthy and
            organised life is the only existing solution so far for the delay of
            cognitive decline in elderly adults (Lee, 2023). A healthy life
            means having a routine consisting of a balanced diet, an appropriate
            set of exercises done daily, constant social interaction with family
            and friends, and cognitive exercises to keep intellectually active.
            A recent study by Jia et al. showed the direct corelation between a
            healthy lifestyle and slower memory decline (Jia et al., 2022).
          </p>

          <p>
            Of course, a healthy diet and regular exercise are well known
            factors for leading a happy, stress-free life, but what about social
            activities and cognitive exercise? These activities are sometimes
            difficult to integrate in the lives of elderly individuals,
            especially if they suffer from dementia. However, there is a simple
            solution for this problem: serious online games for elderly adults.
            These games can cover both the need for intellectual training, by
            asking the senior to complete certain tasks, and the need for social
            interaction, with the possibility of playing online, in multiplayer
            mode.{" "}
          </p>
          <p>
            The elderly population worldwide is a big fan of playing games.
            Single player games, such as sudoku, crosswords and several other
            puzzles, but also multiplayer games, such as bingo, chess, mahjong,
            rummy and cards, are popular within close-knit communities. Single
            player games are useful for cognitive exercise, but they do not
            satisfy the need for social interaction and, after a while, they can
            become boring for the seniors. Multiplayer games are very fun and
            entertaining, but the problem arises when elderly adults live in
            vast cities or communities, where it is harder for them to find
            peers to play with. The CAREUP Project was created with this
            specific issue in mind, integrating serious online games into the
            future health platform.
          </p>
          <p>
            Serious digital games can be either adaptations of already popular
            senior games, or they can be something new altogether. For example,
            the digital game ‘In Anticipation of Death’ (Sauvé, Kaufman &
            Plante, 2019) is an adapted version of Solitaire, while the digital
            game ‘Live Well, Live Healthy!’ is an adaptation of Bingo (Sauvé,
            Renaud, Kaufman & Duplàa, 2015). The other type of games, with new
            concepts, usually have certain aims. For example, the 3D game of the
            VIRTRA-EL platform reminds seniors how to take care of their own
            needs inside and outside the house by simulating a home environment
            (Rodriguez-Fortiz et al., 2016); similarly, the Harvest and Cook
            games require players to harvest vegetables from their virtual
            garden and then prepare food with them (Yun et al., 2020).
          </p>
          <p>
            Another important classification of serious digital games resides in
            whether they require cognitive exercise or physical exercise. The
            ones centred on intellectual exercise train particular cognitive
            functions, such as memory, attention, observation, spatial and
            temporal orientation, perception etc. The physical ones, also known
            as exergames, have the aim of training the body. An example of this
            is the FallSensing game suite, which is multiplayer, and requires
            players to move certain parts of their bodies in order to make their
            pinguin alter egos move and complete the tasks the game demands
            (Silva et al., 2018).
          </p>
          <p>
            Serious digital games can be used for both prevention and
            rehabilitation purposes, with special emphasis on physiological,
            psychological, social and sensory–motor aspects. The population of
            elderly adults is constantly increasing, and it is the duty of the
            scientific and care domains to find better ways to help them deal
            with the process of aging.
          </p>
          <h5>Bibliography</h5>
          <ol>
            <li>
              Prevalence of dementia in Europe (2021) Alzheimer Europe.
              Available at:
              https://www.alzheimer-europe.org/dementia/prevalence-dementia-europe
              (Accessed: April 4, 2023).
            </li>
            <li>
              Lee, Y.J. (2023) An MIT neuroscientist says 'routine' and
              'discipline' are the keys to preserving memory and staving off
              dementia, Business Insider. Business Insider. Available at:
              https://www.businessinsider.com/mit-neuroscientist-discipline-routine-preserving-memory-dementia-aging-2023-3
              (Accessed: April 4, 2023).
            </li>
            <li>
              dementia-aging-2023-3 (Accessed: April 4, 2023). Jia, J. et al.
              (2023) “Association between healthy lifestyle and memory decline
              in older adults: 10 year, population based, prospective Cohort
              Study,” BMJ [Preprint]. Available at:
              https://doi.org/10.1136/bmj-2022-072691.
            </li>
            <li>
              Sauvé, L., Kaufman, D. and Plante, P. (2019). Designing a
              user-friendly educational game for older adults. Lecture Notes in
              Computer Science, pp. 39–46. doi:10.1007/978-3-030-35758-0_4
            </li>
            <li>
              Sauvé, L., Renaud, L., Kaufman, D., & Duplàa, E. (2015).
              Validation of the Educational Game for Seniors: “Live Well, Live
              Healthy!”. Procedia - Social and Behavioral Sciences, 176,
              674–682. doi:10.1016/j.sbspro.2015.01.526.
            </li>
            <li>
              Rodriguez-Fortiz, M. J., Rodriguez-Dominguez, C., Cano, P.,
              Revelles, J., Rodriguez-Almendros, M. L., Hurtado-Torres, M. ., &
              Rute-Perez, S. (2016). Serious games for the cognitive stimulation
              of elderly people. 2016 IEEE International Conference on Serious
              Games and Applications for Health (SeGAH).
              doi:10.1109/segah.2016.7586261.
            </li>
            <li>
              Silva, J., Oliveira, E., Moreira, D., Nunes, F., Caic, M.,
              Madureira, J., & Pereira, E. (2018). Design and Evaluation of a
              Fall Prevention Multiplayer Game for Senior Care Centres. Lecture
              Notes in Computer Science, 103–114.
              doi:10.1007/978-3-319-99426-0_9.
            </li>
          </ol>
        </div>
      </div>    
  );
};

export default Blog6;
