import "./Press.css";
import { Link, NavLink } from "react-router-dom";

const PressDe = () => {
  return (
    <div className="container">
      <div className="row" id="main-title">
        <h1>Press Release</h1>
      </div>
      <div className="row languages">
        <h6>
          <button>
            <Link to="/press">EN</Link>
          </button>
          |
          <button id="IT">
            <NavLink to="/press-fr">FR</NavLink>
          </button>
          |
          <button>
            <NavLink to="/press-pol">POL</NavLink>
          </button>
          |
          <button>
            <NavLink to="/press-it">IT</NavLink>
          </button>
          |
          <button>
            <NavLink to="/press-ro">RO</NavLink>
          </button>
        </h6>
      </div>
      <div className="row" id="press">
        <h1>
          Integrierte Versorgungsplattform basierend auf der Überwachung der
          intrinsischen Kapazität älterer Menschen für integrative
          Gesundheit(CAREUP)
        </h1>
        <h6>Pressemitteilung/ 30. Juni 2022</h6>
        <p>
          Das CARUP-Projekt, das von ECLEXYS Sagl koordiniert wird, untersucht,
          wie der "integrative Gesundheits- und Pflegeansatz
          (Gesundheitsprävention, Erhaltung der körperlichen und geistigen
          Gesundheit, soziale Teilhabe)" des AAL-Aufrufs 2021 umgesetzt werden
          kann, indem die spezifischen Bedürfnisse der Endnutzer berücksichtigt
          werden. Die primären Endnutzer, ältere Erwachsene ab 65 Jahren, klagen
          häufig über ein nachlassendes Energieniveau, selbst wenn keine
          klinisch offensichtliche Krankheit vorliegt (nachlassende
          Leistungsfähigkeit und Verlust von Reserven oder Robustheit), und
          haben Schwierigkeiten, einen Sinn im Leben zu finden und sich Ziele zu
          setzen. Wir argumentieren, dass diese Aspekte auf das Fehlen eines
          geeigneten Life-Coach-Ansatzes zurückzuführen sind. Das CAREUP-Projekt
          schlägt eine IKT-gestützte Lösung zur kontinuierlichen Verbesserung
          der Lebensqualität älterer Erwachsener vor, die sich am Lebensverlauf
          orientiert und das Konzept der "Positiven Gesundheit" (PH) sowie das
          Konzept des "Gesunden Alterns" (HA) der WHO nutzt. Die CAREUP-Lösung
          ermöglicht es älteren Erwachsenen, sich an PH/HA zu halten, indem sie
          die Schlüsselelemente selbst überwachen: Funktionelle Fähigkeit (FA)
          und intrinsische Kapazität (IC). Die FA umfasst die IC des Individuums
          und beinhaltet alle mentalen, sozialen, kognitiven und physischen
          Fähigkeiten, auf die eine Person zurückgreifen kann, wie z.B. die
          Fähigkeit zu gehen, zu denken, zu fühlen, zu interagieren, zu
          reflektieren, zu sehen, zu hören und sich zu erinnern.
        </p>
        <br />
        <p>
          Um die oben genannten Ziele zu erreichen, schlägt das Projekt einen
          personenzentrierten und integrierten Ansatz vor, um eine IKT-Plattform
          zu entwerfen und zu implementieren, die in der Lage ist, die IC und FA
          von Personen in Bezug auf ihre spezifischen Umgebungen zu überwachen,
          um ihre altersbedingten Beeinträchtigungen durch die Entwicklung eines
          maßgeschneiderten und personalisierten Pflegeplans mit
          Multikomponenten-Interventionen zu kompensieren. Die CAREUP-Plattform
          erfordert fast keine Handlungen des Benutzers. Menschliche Interaktion
          wird mit IT-Technologie kombiniert.
        </p>
        <br />
        <p>
          Die Plattform wurde zu geringen Kosten entwickelt und ist offen für
          Erweiterungen mit neuen Sensoren, Fragebögen und Spielen. Für die
          sekundären Endnutzer (formelle und informelle Pflegekräfte) besteht
          das Hauptbedürfnis, das CAREUP für sie bewerten wird, darin, das
          richtige/globale Pflegemodell vorzuschlagen, das zu einem integrierten
          Pflegeansatz für den gesamten Lebensverlauf einer Person führt, der
          den Schwerpunkt von der Heilung einer Krankheit auf die Optimierung
          der Lebensqualität einer Person während ihres gesamten Lebens
          verlagert. Das Hauptproblem für die sekundären Endnutzer wird der
          Zeit- und Arbeitsaufwand sein, der für die Installation und Wartung
          der Plattform und die Schulung der Endnutzer zu deren Nutzung
          erforderlich ist. Daher wird die Plattform gemeinsam mit primären und
          sekundären Nutzern, informellen und formellen Nutzern (Ärzten,
          Sozialarbeitern, Psychologen usw.) entwickelt, um unser
          Alleinstellungsmerkmal zu verwirklichen. Für die tertiären Endnutzer
          (Stakeholder) wird die CAREUP-Lösung ihren Bedarf an der Förderung
          eines Ökosystems für gesundes Altern erfüllen, einschließlich
          erschwinglicher Gesundheitsfürsorge und Geschäftsmöglichkeiten für
          einen gesunden Lebensstil sowie der Kosteneffizienz der
          vorgeschlagenen Lösungen für sie.
        </p>
        <p>
          Das Konsortium des CAREUP-Projekts (Link zum Konsortium) ist mit 9
          Partnern aus 5 Ländern geografisch sehr ausgewogen. Das Konsortium
          besteht aus drei Kategorien von Partnern: eine Universität und ein
          F&E-Zentrum, ein Endnutzer und F&E, zwei Endnutzer und 5 KMU.
        </p>
        <p>
          Für weitere Informationen besuchen Sie bitte (
          <Link to="/">CAREUP</Link>)
        </p>
      </div>
    </div>
  );
};

export default PressDe;
