import "./Profiles.css";
const Oct = () => {
  return (
    <div className="container">
      <div className="row" id="main-title">
        <h1>Octilium</h1>
      </div>
      <div className="row" id="partner-intro">
        <div className="col">
          <div id="partnerInroduction">
              OCT, Octilium Sagl, (CH) is a R&D SME with a large knowledge base and experience 
              in data sciences. The company counts a group of professionals with experience in 
              AI/ML techniques, classical statistics, geodetic systems ad algorithms, blockchain 
              technologies, big data technologies, business plan and risk assessment. Recent project 
              application areas include: health systems, positioning and localization systems, 
              information retrieval and cross-correlation of data from different sources, economic 
              models and business strategies, information visualization, network traffic monitoring, 
              interactive technologies, analysis of data and information exchanged in social-network. 
              To know more about us visit our{" "}
              <a href="https://www.octilium.ch" target="_blank" rel="noreferrer">                
                website
              </a>
              .
          </div>
        </div>
      </div>
      <div className="row">
        <h2 id="projectRole">Project Role</h2>
      </div>

      <div className="row" id="projectRoleDetails">
        <div className="col">
          <p id="projectRoleIntroduction">
            OCT is involved mainly in hardware and software integration,
            development of AI/ML algorithms, data analysis and visualization and
            in business plans. OCT participates in all WPs, with the biggest
            effort in WP2 (Platform design, implementation and integration) and
            WP4 (Business model design, dissemination and exploitation). OCT
            will lead task 4.3
          </p>
        </div>
      </div>
      <div className="row">
        <h2 id="projectRelatedExpertise">Related Expertise </h2>
      </div>

      <div className="row" id="projectRelatedExpertiseDetails">
        <div className="col">
          <p id="projectRelatedExpertiseIntroduction">
            OCT has collaborated with the Israeli firm Nogah Photonics Ltd. in:
            ‘Data collection from telemedicine sensors’, with the Management
            Software for data collection from a big data set of sensors (>5000)
            installed in critical utilities across the country, for early
            monitoring of malicious attempts to compromise the functionality of
            the same.
          </p>
        </div>
      </div>

      <div className="row">
        <h2 id="teamMembers">Team Members</h2>
      </div>
      <div className="row">
        <div className="col" id="teamMemberDetails">
          <h5 id="teamMemberName">Lionello Ferrazzini</h5>
          <p id="memberIntroduction">
            He is acting as programme manager for this project. He holds a
            Bachelor of Science in Business Administration from SSQEA/SUPSI and
            a federal diploma as analyst/programmer. He has 30+ year experience
            in private economy in business consulting and accounting, but also
            in computer systems management and database development and
            programming.
          </p>
        </div>
      </div>

      <div className="row">
        <div className="col" id="teamMemberDetails">
          <h5 id="teamMemberName">Seif Ben Bader</h5>
          <p id="memberIntroduction">
            He obtained his BSc. and MSc. degrees at the Swiss Federal Institute
            of Technology (EPF Lausanne), in respectively Mathematics and
            Computational Sciences. He then finalised his Ph.D. in multilevel
            uncertainty quantification for cardiac electrophysiology at the
            Institute of Computational Science (ICS), in the University of
            Lugano. His research interests cover a wide range of fields, such as
            Finite Elements Methods, High Performance Computing, Artificial
            Intelligence and various biomedical applications. In Octilium, as
            member of the R&D team, he is responsible of developing Machine
            Learning solutions for different fields of applications. This
            includes the CAREUP project.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Oct;
