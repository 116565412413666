import "./Profiles.css";
const Aaif = () => {
  return (
    <div className="container">
      <div className="row" id="main-title">
        <h1>Ana Aslan International Foundation</h1>
      </div>

      <div className="row" id="partner-intro">
        <div className="col">
          <div id="partnerInroduction">
            Ana Aslan International Foundation - AAIF, (RO) ,the field of
            research, education, and high-profile medical services, with special
            expertise in Healthy Aging and Brain Aging. Its mission is to
            integrate scientific progress into the medical and social practice
            of older adults’ care and support them live a longer, healthier, and
            happier life. In a new era of personalized medicine, the Foundation
            has expanded its expertise in the field of applied ICT technologies
            dedicated to improving people's health and assisting both healthy
            and frail elderly. This vision transforms how we manage peoples'
            health, focusing on predictive prevention and early detection of
            disease, targeted intervention, and inclusive health. To know more
            about us visit our{" "}
            <a href="https://anaaslanacademy.ro/en" target="_blank" rel="noreferrer">              
              website
            </a>
            .
          </div>
        </div>
      </div>
      <div className="row">
        <h2 id="projectRole">Project Role</h2>
      </div>

      <div className="row" id="projectRoleDetails">
        <div className="col">
          <p id="projectRoleIntroduction">
            Throughout the project, AAIF is an end-user organization,
            responsible for the involvement of primary, secondary, and tertiary
            end-users and relevant stakeholders, which is essential to the
            platform development and how the proposed system will be used by the
            user to improve their health. Thus, we contribute to capturing the
            requirements from an end-user point of view and provide collective
            user feedback to the technological partners. Also, AAIF is a pilot
            site, active in testing with people in Romania. Our team has
            responsibilities related to ethical excellence issues and respecting
            human rights. Also, AAIF is responsible for the engagement of
            healthcare professionals and brings medical expertise to the CAREUP
            system to become a healthcare ecosystem. The multidisciplinary care
            plan and patient-oriented health interventions will target the
            person's performance in positive health domains. Related to the
            project's promotion and dissemination of results via scientific
            publication, AAIF is involved in writing and presenting papers to
            the local and international scientific events and submitting
            articles to journals and publications, throughout the project.
          </p>
        </div>
      </div>
      <div className="row">
        <h2 id="projectRelatedExpertise">Related Expertise </h2>
      </div>

      <div className="row" id="projectRelatedExpertiseDetails">
        <div className="col">
          <p id="projectRelatedExpertiseIntroduction">
            In the past 16 years, AAIF has participated in many European
            projects as an end-user organization, medical partner, and pilot
            site within FP6-IST-STREP (K4CARE and SHARE-it), AAL projects
            (MobileSage, CONFIDENCE, Mobile.Old, CarerSupport, StayActive,
            MyMate, SeniorTV, TSBank, PETAL, IOANNA, Ella4Life, POSITIVE,
            frAAgiLe, VirtuAAL SCP, iCan, ReMember-Me, SI-FOOtWORK, etc) and
            networking projects (Ageing Well, LivWell and E-No-Falls). Also,
            AAIF participates in the 5 years-long large Horizon 2020 SMART BEAR
            Project being responsible to implement the pilot testing for the
            1,000 older adults from Romania. As well, AAIF has been involved in
            interdisciplinary research and innovation networks under the
            auspices of COST (European Cooperation in Science and Technology)
            Actions.{" "}
          </p>
        </div>
      </div>

      <div className="row">
        <h2 id="teamMembers">Team Members</h2>
      </div>
      <div className="row">
        <div className="col" id="teamMemberDetails">
          <h5 id="teamMemberName">Prof. Luiza Spiru </h5>
          <p id="memberIntroduction">
            She dolds MD, PhD. She is the chair of the Geriatrics-Gerontology
            Dept. in the “Carol Davila” University of Medicine and Pharmacy from
            Bucharest, the head of the Dept. of Geriatrics within “Elias”
            University Emergency Clinic Hospital, and also, the President of
            ANA. She holds 3 medical specialties: Geriatrics-Gerontology, Old
            Age Psychiatry, and Internal Medicine is also certified in the
            Management of Health Care Services. She has authored 3 treatises on
            Geriatric medicine, 6 guides on the diagnosis and treatment of
            Alzheimer's disease and related dementias, and has over 150
            published articles in international journals and books of
            proceedings – the most recent ones addressing the integration of
            innovative IT&C solutions for the senior patients. She has been
            appointed as EU Commission Expert as evaluator & rapporteur for the
            project applications under several EU calls; also, has been selected
            to attend and contribute to every yearly JP-AAL workshop since 2014
            onwards. She acted as Project Director for all AAIF's more than 20
            EU projects and she also applied her extensive medical expertise,
            based on an integrated approach, to accurately define user needs and
            requirements and also cover effectively the delicate ethical aspects
            of personal data protection and privacy related to the development
            and testing of ICT solutions.
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col" id="teamMemberDetails">
          <h5 id="teamMemberName">Mircea Marzan</h5>
          <p id="memberIntroduction">
            He dolds MD, EMBA, PhD candidate. He is a medical doctor since 1993
            and he also holds an Executive MBA since 1999, currently involved in
            the management of all European and National R&D projects. He has
            over fifteen years of executive managerial experience (acquired
            mainly in the pharma industry) and more than seven years of
            participating in the implementation of ICT projects to develop,
            promote, and using assistive technologies solutions for older
            adults, especially for those with special cognitive needs.
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col" id="teamMemberDetails">
          <h5 id="teamMemberName">Cosmina Paul </h5>
          <p id="memberIntroduction">
            She holds PhD, MScA in Research Design and Data Analysis in Social
            Sciences, Yale University Fellow. She is an experienced researcher
            in social sciences and an expert in international project
            management. Through her professional activities and personal
            initiatives in the field of community-oriented social services, she
            developed an important network with representatives of
            municipalities and NGOs that provides access to an extended database
            of possible participants in large pilot testing for innovative
            solutions.
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col" id="teamMemberDetails">
          <h5 id="teamMemberName">Magdalena Velciu</h5>
          <p id="memberIntroduction">
            She holds PhD, Senior Researcher. She has a multidisciplinary
            educational background in social sciences, and human resources
            management, and experience in fundamental and applicative research
            work dedicated to the challenges of aging population. She conducts
            user research and investigations to enhance the user experience and
            address all behavioral factors and socio-economic conditions
            influencing a person's overall health.{" "}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Aaif;
