import "./Blogs.css";
import { NavLink } from "react-router-dom";
const Blog1 = () => {
  return (
    <div className="container  ">
      <div className="row blogs" id="main-title">
        <NavLink to="/blogs">
          <h1>Blogs</h1>
        </NavLink>
      </div>
      <div className="row full_blogs">
        <h4>
          Integrated Care Platform Based on the Monitoring of Older Individual
          Intrinsic Capacity for Inclusive Health (CAREUP)
        </h4>
        <p class="card-text">
          <small className="text-muted">Published on 28 September 2022</small>
        </p>
        <p>
          The CAREUP project investigates in the implementation of the
          “inclusive health & care approach (health prevention, preservation of
          physical & mental health, social participation)”, which covers elders
          (65+) who face difficulty in finding sense of purpose in life and
          difficulty with goals setting which is due to the lack a proper
          life-coach approach.
        </p>
        <p>
          AREUP will present an ICT-based solution to continuously enhance the
          quality of life of older adults that meets the Positive Heath (PH)
          concept and the WHO Healthy Ageing (HA). This solution will allow aged
          people to adhere to PH/HA self-monitoring. The key element of this
          solution are Functional Ability (FA) and Intrinsic Capacity (IC).
        </p>
        <p>
          The FA comprises the IC of the individual which includes all the
          mental, social, cognitive, and physical capacities that a person can
          draw on, that includes their ability to walk, think, feel, interact,
          reflect, see, hear, and remember. This can be achieved through a
          person-centered and integrated ICT platform which has the possibility
          of monitoring the IC and FA of individuals in relation with their
          specific environments. This will help to compensate the older adults
          declines due to ageing through development of a customized and
          personalized care plan with multicomponent interventions.
        </p>
        <p>
          Finally, the CAREUP platform is built at low costs and is open for
          extensions with new sensors, questionnaires and games.
        </p>
      </div>
    </div>
  );
};

export default Blog1;
