import { NavLink } from "react-router-dom";
import "./Blogs.css";
const Blog5 = () => {
  return (    
      <div className="container  ">
        <div className="row blogs" id="main-title">
          <NavLink to="/blogs">
            <h1>Blogs</h1>
          </NavLink>
        </div>
        <div className="row full_blogs">
          <h4>
            CAREUP as a tool for elderly people assistance in the frame of
            revised EU pandemic plans
          </h4>
          <p class="card-text">
            <small className="text-muted">Published on 25 February 2023</small>
          </p>
          <p>
            Although the COVID-19 pandemic seems to be slowing down in many
            parts of the world, EU states are taking steps to update their
            pandemic plans in preparation for potential future waves of the
            disease. One of the most pressing issues is how to support elderly
            people during lockdowns, particularly in societies where family
            support has become less available.
          </p>
          <p>
            In response to this challenge, the EU CAREUP project has developed a
            platform useful also to support older adults in maintaining their
            physical and mental well-being during the pandemic. In case of
            another surge of COVID-19 with restrictions in freedom of movement,
            older adults can take advantage of the platform in the following
            ways:
          </p>
          <ul>
            <li>
              Stay informed: The platform provides up-to-date information on the
              COVID-19 pandemic, including news and guidelines from public
              health authorities. This can help older adults stay informed about
              the latest developments and make informed decisions about their
              health and safety.
            </li>
            <li>
              Access resources for staying active: The platform includes a range
              of resources for staying active at home, including exercise
              videos, workout plans, and activity challenges. These resources
              can help older adults maintain their physical health and
              well-being even if they are unable to leave their homes.
            </li>
            <li>
              Connect with others: The platform includes a social networking
              feature that allows older adults to connect with others in their
              community. This can help to combat social isolation and
              loneliness, which can be particularly challenging for older adults
              during the pandemic.
            </li>
            <li>
              Access mental health support: The platform includes resources and
              tools for managing stress, anxiety, and other mental health
              concerns. This can be particularly important during times of high
              stress and uncertainty, such as during a surge in the COVID-19
              epidemic.
            </li>
            <li>
              Receive personalized support: The platform includes personalized
              support and guidance based on each user's needs and preferences.
              This can help older adults to access the support and resources
              that are most relevant to their individual circumstances.
            </li>
          </ul>
          <p>
            The EU CAREUP project is currently being presented to experts in
            Italy and Switzerland as a tool to be integrated into emergency
            plans in case of new outbreaks of COVID-19. By utilizing the
            platform, elderly people in these societies where family support may
            be lacking, can still access resources and support during periods of
            forcible stay at home.
          </p>
          <p>
            In conclusion, the EU CAREUP project provides a valuable resource
            for supporting elderly people during lockdowns, and its integration
            into emergency plans in Italy and Switzerland can help to ensure
            that elderly people have access to the resources and support they
            need during future outbreaks of COVID-19.
          </p>
        </div>
      </div>    
  );
};

export default Blog5;
