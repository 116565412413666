import "./Press.css";
import { Link, NavLink } from "react-router-dom";

const PressPol = () => {
  return (
    <div className="container">
      <div className="row" id="main-title">
        <h1>Press Release</h1>
      </div>
      <div className="row languages">
        <h6>
          <button>
            <Link to="/press">EN</Link>
          </button>
          |
          <button id="IT">
            <NavLink to="/press-fr">FR</NavLink>
          </button>
          |
          <button id="IT">
            <NavLink to="/press-de">DE</NavLink>
          </button>
          |
          <button>
            <NavLink to="/press-it">IT</NavLink>
          </button>
          |
          <button>
            <NavLink to="/press-ro">RO</NavLink>
          </button>
        </h6>
      </div>

      <div className="row" id="press">
        <h1>
          Zintegrowana platforma inkluzywnej opieki nad osobami starszymi oparta
          na monitorowaniu zdolności wewnętrznych (CAREUP)
        </h1>
        <h6>Komunikat prasowy/ 30 czerwca 2022</h6>
        <p>
          Celem projektu CAREUP (AAL Call 2021), koordynowanego przez ECLEXYS
          Sagl, jest przeprowadzenie badań i wdrożenie platformy zapewniającej
          inkluzywne podejście do zdrowia i opieki (polegające na zapobieganiu
          pogorszeniu się stanu zdrowia, zachowaniu zdrowia fizycznego i
          psychicznego, utrzymaniu partycypacji społecznej) z uwzgędnieniem
          zindiwidualizowanych potrzeb użytkowników platformy. Głównymi
          użytkownikami platformy będą osoby starsze, w wieku 65+, często
          skarżące się na spadający poziom witalności i odporności (nawet przy
          braku jawnej klinicznie choroby), napotykające na trudności w
          znalezieniu sensu życia i trudności w wyznaczaniu celów życiowych.
          Aspekty te wynikają z braku odpowiedniego interaktywnego wsparcia i
          treningu rozwoju. W projekcie CAREUP zaproponowano rozwiązanie
          teleinformatyczne, stymulujące poprawę jakości życia osób starszych
          zgodnie z koncepcją „pozytywnego zdrowia” (Positive Health - PH) i
          opracowaną przez WHO koncepcją „zdrowego starzenia się” (Healthy Aging
          - HA). Platforma CAREUP umożliwia osobom starszym dopasowanie się do
          reguł PH/HA poprzez samokontrolę i ocenę kluczowych elementów:
          zdolności funkcjonalnych (Functional Ability - FA) i zdolności
          wewnętrznych (Intrinsic Capacity - IC). Zdolności funkcjonalne zależą
          od środowiska, w którym osoba żyje oraz od zdolności wewnętrznych
          osoby takich jak: zdolności umysłowe, społeczne, poznawcze i fizyczne,
          zdolność do chodzenia, myślenia, odczuwania, interakcji, refleksji,
          widzenia, słyszenia i zapamiętywania. Aby osiągnąć powyższe cele,
          platforma będzie projektowana i realizowana zgodnie z następującymi
          paradygmatami: skoncentrowaniem działań na potrzebach osób starszych i
          udziale tych osób w obu procesach.
        </p>
        <br />
        <p>
          Działanie platformy CAREUP będzie polegało na monitorowaniu zdolności
          funkcjonalnych i zdolności wewnętrznych osób w odniesieniu do ich
          specyficznych środowisk w celu kompensowania spadków zdolności z
          powodu starzenia się. W tym celu będą opracowywane i wdrażane
          spersonalizowane plany opieki. Dzięki zastosowaniu zaawansowanej
          technologii informatycznej platforma CAREUP nie wymaga prawie żadnych
          działań ze strony użytkownika. Platforma jest zbudowana z komercyjnie
          dostępnych elementów, co pozwala na ograniczenie jej kosztu, możliwe
          jest jej rozszerzenie o nowe czujniki, kwestionariusze i gry. W
          przypadku osób zaangażowanych w opiekę nad osobami starszymi
          (opiekunów formalnych i nieformalnych) CAREUP proponuje model
          świadczenia opieki, oparty na zintegrowanym podejściu stosowanym przez
          całe życie danej osoby. Model ten przenosi ciężar z leczenia chorób na
          optymalizację zdolności wewnętrzej osoby.
        </p>
        <br />
        <p>
          Typowym, głównym problemem użytkowników platform teleinformatycznych
          jest czas i wysiłek potrzebny na zainstalowanie i konserwację
          platformy oraz przeszkolenie w zakresie jej użytkowania. Aby
          zminimalizować ten problem platforma jest współtworzona wraz z jej
          przyszłymi użytkownikami (m.in. osobami starszymi, opiekunami
          nieformalnymi i formalnymi, lekarzami, pracownikami opieki społecznej,
          psychologami). Platforma CAREUP zaspokoi również potrzeby
          interesariuszy instytucjonalnych w zakresie wspierania zdrowego
          ekosystemu starzenia się, w tym możliwości biznesowych w obszarze
          niedrogiej opieki zdrowotnej i zdrowego stylu życia.
        </p>
        <p>
          Konsorcjum projektu CAREUP (link to consortium) tworzy 9 partnerów z 5
          europejskich krajów. W skład konsorcjum wchodzą partnerzy o różnym
          profilu działalności: jedna uczelnia techniczna, centrum
          badawczo-rozwojowe, dwie firmy zapewniające opiekę nad osobami
          starszymi i 5 małych i średnich przedsiębiorstw (MŚP).
        </p>
        <p>
          Więcej informacji na stronie (<Link to="/">CAREUP</Link>)
        </p>
      </div>
    </div>
  );
};

export default PressPol;
