import "./Leaflets.css";
import { NavLink, Link } from "react-router-dom";

const Leaflets = () => {
  return (
    <div className="container">
      <div className="row" id="main-title">
        <h1>Leaflets and Posters</h1>
      </div>
      <div className="row">
        <p id="leaflets-main-intro">
          CAREUP flyers and posters.
        </p>
      </div>    
      <div className="row" id="leaflets-title">
        <h3>
          Leaflets
        </h3>
      </div>
      <br />
      <table className="table" id="leaflets-table">
        <tbody id ="leafleft-li">
            <tr>
              <td>
                <li>
                  <a href="../static/leaflets/CAREUP project leaflet_DEU.pdf" target="_blanck" rel="noreferrer">            
                    CAREUP project leaflet (DEU)</a>
                </li>
              </td>
              <td>
                <img src="../static/img/germany.png" alt="DE" width="30px" height="20px"></img>
              </td>
            </tr>
            <tr>
              <td>
                <li>
                  <a href="../static/leaflets/CAREUP project leaflet_ENG.pdf" target="_blanck" rel="noreferrer">
                  CAREUP project leaflet (ENG)</a>
                </li>
              </td>
              <td>
                <img src="../static/img/uk.png" alt="EN" width="30px" height="20px"></img>
              </td>
            </tr>
            <tr>
              <td>
                <li>
                  <a href="../static/leaflets/CAREUP project leaflet_FRA.pdf" target="_blanck" rel="noreferrer">
                  CAREUP project leaflet (FRA)</a>
                </li>
              </td>
              <td>
                <img src="../static/img/france.png" alt="FR" width="30px" height="20px"></img>
              </td>
            </tr>
            <tr>
              <td>
                <li>
                  <a href="../static/leaflets/CAREUP project leaflet_ITA.pdf" target="_blanck" rel="noreferrer">
                  CAREUP project leaflet (ITA)</a>
                </li>
              </td>
              <td>
                <img src="../static/img/italy.png" alt="IT" width="30px" height="20px"></img>
              </td>
            </tr>
            <tr>
              <td>
                <li>
                  <a href="../static/leaflets/CAREUP project leaflet_POL.pdf" target="_blanck" rel="noreferrer">
                  CAREUP project leaflet (POL)</a>
                </li>
              </td>
              <td>
                <img src="../static/img/poland.png" alt="PL" width="30px" height="20px"></img>
              </td>
            </tr>
            <tr>
              <td>
                <li>
                  <a href="../static/leaflets/CAREUP project leaflet_ROM.pdf" target="_blanck" rel="noreferrer">
                  CAREUP project leaflet (ROM)</a>
                </li>
              </td>
              <td>
                <img src="../static/img/romania.png" alt="RO" width="30px" height="20px"></img>
              </td>
            </tr>
          </tbody>
        </table>            
      <div className="row" id="leaflets-title">
        <h3>
          Posters
        </h3>
      </div>
      <br />
      <table className="table" id="leaflets-table">
        <tbody id ="leafleft-li">
            <tr>
              <td>
                <li>
                  <a href="../static/posters/Poster CARE-UP_DEU.pdf" target="_blanck" rel="noreferrer">            
                    CAREUP Poster (DEU)</a>
                </li>
              </td>
              <td>
                <img src="../static/img/germany.png" alt="DE" width="30px" height="20px"></img>
              </td>
            </tr>
            <tr>
              <td>
                <li>
                  <a href="../static/posters/Poster CARE-UP_ENG.pdf" target="_blanck" rel="noreferrer">
                    CAREUP Poster (ENG)</a>
                </li>
              </td>
              <td>
                <img src="../static/img/uk.png" alt="EN" width="30px" height="20px"></img>
              </td>
            </tr>
            <tr>
              <td>
                <li>
                  <a href="../static/posters/Poster CARE-UP_FRA.pdf" target="_blanck" rel="noreferrer">
                    CAREUP Poster (FRA)</a>
                </li>
              </td>
              <td>
                <img src="../static/img/france.png" alt="FR" width="30px" height="20px"></img>
              </td>
            </tr>
            <tr>
              <td>
                <li>
                  <a href="../static/posters/Poster CARE-UP_ITA.pdf" target="_blanck" rel="noreferrer">
                    CAREUP Poster (ITA)</a>
                </li>
              </td>
              <td>
                <img src="../static/img/italy.png" alt="IT" width="30px" height="20px"></img>
              </td>
            </tr>
            <tr>
              <td>
                <li>
                  <a href="../static/posters/Poster CARE-UP_POL.pdf" target="_blanck" rel="noreferrer">
                    CAREUP Poster (POL)</a>
                </li>
              </td>
              <td>                
                <img src="../static/img/poland.png" alt="PL" width="30px" height="20px"></img>
              </td>
            </tr>
            <tr>
              <td>
                <li>
                  <a href="../static/posters/Poster CARE-UP_ROM.pdf" target="_blanck" rel="noreferrer">
                    CAREUP Poster (ROM)</a>
                </li>
              </td>
              <td>
                <img src="../static/img/romania.png" alt="RO" width="30px" height="20px"></img>
              </td>
            </tr>
          </tbody>
        </table>    
    </div>
  );
};

export default Leaflets;
