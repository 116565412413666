import "./Press.css";
import { Link, NavLink } from "react-router-dom";

const PressRo = () => {
  return (
    <div className="container">
      <div className="row" id="main-title">
        <h1>Press Release</h1>
      </div>
      <div className="row languages">
        <h6>
          <button>
            <Link to="/press">EN</Link>
          </button>
          |
          <button id="IT">
            <NavLink to="/press-fr">FR</NavLink>
          </button>
          |
          <button id="IT">
            <NavLink to="/press-de">DE</NavLink>
          </button>
          |
          <button>
            <NavLink to="/press-pol">POL</NavLink>
          </button>
          |
          <button>
            <NavLink to="/press-it">IT</NavLink>
          </button>
        </h6>
      </div>

      <div className="row" id="press">
        <h1>
          Platformă de îngrijire integrată bazată pe monitorizarea capacității
          intrinseci individuale în vârstă de sănătate incluzivă (CAREUP)
        </h1>
        <h6>Comunicat de presă/ 30 iunie 2022</h6>
        <p>
          Proiectul CAREUP, coordonat de ECLEXYS Sagl, investighează modul de
          implementare a „abordării inclusive de sănătate și îngrijire
          (profilaxie, păstrarea sănătății fizice și mintale, participare
          socială)” a Apelului AAL 2021”, luând în considerare nevoile specifice
          a utilizatorilor finali. Utilizatorii finali, primari, adulții în
          vârstă, cu vârsta de peste 65 de ani, se plâng adesea de scăderea
          nivelului de energie, chiar și în absența unei boli evidente din punct
          de vedere clinic (scăderea capacității fizice și/sau mintale,
          fragilitate) și se confruntă cu dificultăți în găsirea sensului vieții
          și dificultăți în stabilirea obiectivelor. Susținem că astfel de
          aspecte se datorează lipsei unei abordări adecvate a vieții. Proiectul
          CAREUP propune o soluție bazată pe TIC pentru a îmbunătăți continuu
          calitatea vieții adulților în vârstă, urmând un cadru-curs de
          organizare a vieții și folosind îndrumarea conceptului de "Sănătate
          Pozitivă” (SP) și a celui „Îmbătrânire Sănătoasă” (IS) al OMS. Soluția
          CAREUP permite adulților în vârstă să adere la SP/IS prin
          automonitorizarea elementelor sale cheie: Abilitatea funcțională (AF)
          și Capacitatea intrinsecă (CI). AF cuprinde CI-ul individului și
          include toate capacitățile mintale, sociale, cognitive și fizice pe
          care o persoană le poate utiliza și include: capacitatea sa de a
          merge, gândi, simți, interacționa, reflecta, vedea, auzi și aminti.
        </p>
        <br />
        <p>
          Pentru a realiza cele menționate mai sus, proiectul propune o abordare
          centrată pe persoană și integrată pentru a construi și implementa o
          platformă TIC care este capabilă să monitorizeze CI și AF a
          indivizilor în relație cu mediile lor specific și pentru a: compensa
          declinul acestora din cauza îmbătrânirii prin dezvoltarea unui plan de
          îngrijire personalizat cu intervenții multi-componente. Platforma
          CAREUP nu necesită aproape nicio acțiune din partea utilizatorului.
          Interacțiunea umană este combinată cu tehnologia IT.
        </p>
        <br />
        <p>
          Platforma este construită cu costuri mici și este deschisă pentru
          extensii cu senzori noi, chestionare și jocuri. Pentru utilizatorii
          finali, secundari (îngrijitorii formali și informali), necesitatea
          principală pe care CAREUP o va evalua pentru aceștia este propunerea
          unui model de furnizare de îngrijire corectă și globală care să
          conducă la o abordare integrată a îngrijirii pe parcursul întregii
          vieți a persoanei, și care să schimbe accentul de la vindecarea bolii,
          pentru a optimiza CI-ul unei persoane de-a lungul vieții. Principala
          problemă pentru utilizatorii finali, secundari, va fi timpul și
          efortul necesar pentru a instala și întreține platforma și pentru a
          instrui utilizatorii finali să o utilizeze. Pentru a face acest lucru,
          platforma este creată împreună cu utilizatorii primari și secundari,
          informali și formali (medici, asistenți sociali, psihologi...) pentru
          a realiza propunerea noastră unică de vânzare. Pentru utilizatorii
          finali terțiari (părțile interesate), soluția CAREUP le va satisface
          nevoia de a promova un ecosistem de îmbătrânire sănătos, inclusiv
          asistență medicală la prețuri accesibile și oportunități de afaceri
          pentru un stil de viață sănătos, precum și rentabilitatea soluțiilor
          propuse pentru aceasta.
        </p>
        <p>
          În cele din urmă, consorțiul proiectului CAREUP (link către consorțiu)
          este bine echilibrat atât în ceea ce privește răspândirea geografică:
          9 parteneri din 5 țări, dar și în ceea ce constă domeniile de
          activitate: sunt trei categorii de parteneri, o universitate care este
          și un centru de cercetare și dezvoltare, un utilizator final, care
          este și centru de cercetare și dezvoltare, doi utilizatori finali și 5
          IMM-uri.
        </p>
        <p>
          Pentru informații suplimentare, vă rugăm să vizitați (
          <Link to="/">CAREUP</Link>)
        </p>
      </div>
    </div>
  );
};

export default PressRo;
