import { NavLink } from "react-router-dom";
import "./Blogs.css";
import art14_image from "../../../static/img/art14.png";
const Blog13 = () => {
  return (
      <div className="container  ">
        <div className="row blogs" id="main-title">
          <NavLink to="/blogs">
            <h1>Blogs</h1>
          </NavLink>
        </div>
        <div className="row full_blogs">
          <h4>
            Home health monitoring technologies for older adults: user acceptance, issues and challenges
          </h4>
          <p class="card-text">
            <small className="text-muted">Published on 29 February 2024</small>
          </p>
          <br />
          <p>
            Smart home and health monitoring technologies are increasingly emerging as the future for managing 
            chronic diseases and allowing people to age in place [1].
          </p>
          <p>
            Examples of home health-monitoring technologies are systems for physiological monitoring (Phys), 
            functional monitoring and emergency detection and response (Fx), safety monitoring and assistance (Saf), 
            security monitoring and assistance (Sec), social interaction monitoring and assistance (Soc), and 
            cognitive and sensory assistance (Cog/Sen) [2]. Moreover, there is a wide range of clinically evaluated 
            activity and health recording devices readily available in the market, including wearables (e.g., wrist 
            bands, chest bands, and textiles) and ambient sensors (e.g., motion sensors, cameras, and pressure 
            sensors) to track physiological parameters, activity levels, and routines or to facilitate rehabilitation 
            and treatment [3]. Devices that enable or prolong independent performance in meaningful activities are met 
            with great enthusiasm by older adults, although some of them still hold reservations regarding how home 
            health monitoring could affect their autonomy and independence [4]. Web-based medical platforms are one 
            example, having low adoptability or acceptance rates, potentially because of inadequate user involvement, 
            especially in the early development phases [5].
          </p>
          <p>
            For that reason, as we are designing and implementing a new web-based medical platform (Fig.1), we wondered 
            what was the evidence for the effectiveness of home-based self-monitoring technologies for improving health 
            and well-being of older adults and what were the ethical concerns that older adults have towards these technologies. 
          </p>
          <img src={art14_image} className="agtech" alt="..." />
          <p align="center"><b>Figure 1. CAREUP platform data flow</b></p>          
          <p></p>
          <p>
            Home health monitoring is defined as the use of technology, omitting telecommunications, to monitor the health 
            of users over time (i.e., remotely) [6]. Self-tracking and self-management are seen as activities that also empower 
            patient autonomy. The use of self-tracking devices focuses on data and participation of patient: two aspects that are 
            crucial to personalized healthcare [7]. By better understanding their illness, patients may gain the awareness 
            of their symptoms as well as the influence of their lifestyle on their condition. Vassilev et al. [8] highlighted 
            that many patients prefer that certain diagnostic measures take place in their home environment because they are 
            uncomfortable with the clinical surroundings in a hospital. In this sense, patient self-care supports autonomy and 
            empowerment through his/her engagement. Through self-monitoring, patients may gain knowledge about biomedical 
            processes and especially about their own condition. According to this approach, devices for self-tracking of biomedical 
            data and self-management of illness give patients the opportunity to take charge of their own health. When home 
            monitoring technology for aging in place is used appropriately, it can improve quality of life, maintain health 
            and wellness of older adults, provide favourable changes in self-efficacy and adherence to treatment, and support 
            other stakeholders [9]. Such technology may allow increased autonomy and independence in older adults while providing 
            additional support for family members or health care professionals [10].
          </p>
          <p>
            Concerning that point, another important aspect concerns the relationship between patient and therapist. There is some 
            evidence for the view that the restructuring of the relationship between patients and health professionals is welcomed by 
            patients, giving them more control over the management of their illness [8]. The continuous monitoring and the enhanced 
            accessibility of health professionals through telecommunication devices have shown a reassuring effect on patients. 
            Studies [11] indicated that trust is the crucial factor in the technically enhanced encounter between patients and health 
            professionals. When a solid basis of trust is established, patients are more likely to accept IT-based systems for 
            communicating with health professionals. In this regard, video-massaging applications might be used to establish a more 
            personal relationship instead of communicating via phone or email. 
          </p>
          <p>
            Since patients have to be able to handle the technical equipment and follow the necessary routine for a reliable collection 
            of data, it cannot be assumed that every patient is familiar with the sometimes-complex workings of telemedical devices [12]. 
            Concerns related to usability, data privacy and control, autonomy, social connectivity, and stigmatization remain major 
            barriers to user adoption and acceptance [5]. Older adults who have negative views on aging and associate it with traits such 
            as “illness,” “loneliness,” or “dependency” tend to neglect assistive technology they believe reflects those ideals, whereas 
            those who see aging as positive are more inclined to accept assistive technology and integrate it into their daily lives [13]. 
            If end users see no benefit in using an assistive device, there is little chance of acceptance. A device that is difficult 
            to operate or understand can lead to frustration and lack of confidence [14]. Other systematic reviews [15,16] asserted that 
            one of the factors leading to limited adoption of home health-monitoring technologies are the lack of information related to 
            technology costs and sustainable reimbursement models. 
          </p>
          <p>
            Although many older adults admit that they see value in home health technology, they also have wide-ranging reservations about it. 
            The ability to anticipate, address, and respond to ethical challenges and concerns is critical for future development and 
            adoption for stakeholders. Communication between all involved stakeholders should occur to better understand attitudes, 
            concerns, and demands of those who are most impacted by technology [17]. We should aim to tailor the technology to the 
            stakeholders’ needs and requirements to increase their usability and acceptance. This would be possible, for example, through 
            the application of the User-centred design (UCD), that is a framework in which the requirements of stakeholders (including end 
            users) are considered extensively at every stage of the product’s development and design [18].
          </p>
          <p></p>
          <div id="article-references">
            <h6>References:</h6>
            <ol>
              <li>
                Mshali, H., Lemlouma, T., Moloney, M., & Magoni, D. (2018). A survey on health monitoring systems for health smart homes. 
                International Journal of Industrial Ergonomics, 66, 26-56.               
              </li>              	
              <li>
                Demiris, G., & Hensel, B. K. (2008). Technologies for an aging society: a systematic review of “smart home” applications. 
                Yearbook of medical informatics, 17(01), 33-40. 
              </li>
              <li>
                Wang, J., Spicher, N., Warnecke, J. M., Haghi, M., Schwartze, J., & Deserno, T. M. (2021). Unobtrusive health monitoring 
                in private spaces: The smart home. Sensors, 21(3), 864. 
              </li>
              <li>
                Marikyan, D., Papagiannidis, S., & Alamanos, E. (2019). A systematic review of the smart home literature: A user perspective. 
                Technological Forecasting and Social Change, 138, 139-154. 
              </li>
              <li>  
                Chan, A., Cohen, R., Robinson, K. M., Bhardwaj, D., Gregson, G., Jutai, J. W., ... & Fekr, A. R. (2022). Evidence and user 
                considerations of home health monitoring for older adults: scoping review. JMIR aging, 5(4), e40079. 
              </li>
              <li>
                Ogawa, M., & Togawa, T. (2003, June). The concept of the home health monitoring. In Proceedings 5th International Workshop on 
                Enterprise Networking and Computing in Healthcare Industry (HealthCom) (pp. 71-73). IEEE. 
              </li>
              <li>
                Sharon, T. (2017). Self-tracking for health and the quantified self: Re-articulating autonomy, solidarity, and authenticity in 
                an age of personalized healthcare. Philosophy & Technology, 30(1), 93-121. 
              </li>
              <li>
                Vassilev, I., Rowsell, A., Pope, C., Kennedy, A., O’Cathain, A., Salisbury, C., & Rogers, A. (2015). Assessing the 
                implementability of telehealth interventions for self-management support: a realist review. Implementation Science, 10, 1-25. 
              </li>
              <li>  
                Chung, J., Demiris, G., & Thompson, H. J. (2016). Ethical considerations regarding the use of smart home technologies for older 
                adults: an integrative review. Annual review of nursing research, 34(1), 155-181. 
              </li>
              <li>
                Panico, F., Cordasco, G., Vogel, C., Trojano, L., & Esposito, A. (2020). Ethical issues in assistive ambient living technologies 
                for ageing well. Multimedia Tools and Applications, 79, 36077-36089. 
              </li>
              <li>
                Andreassen, H. K., Trondsen, M., Kummervold, P. E., Gammon, D., & Hjortdahl, P. (2006). Patients who use e-mediated 
                communication with their doctor: new constructions of trust in the patient-doctor relationship. Qualitative health research, 
                16(2), 238-248. 
              </li>
              <li>
                Rubeis, G., Schochow, M., & Steger, F. (2018). Patient autonomy and quality of care in telehealthcare. Science and 
                Engineering Ethics, 24, 93-107. 
              </li>
              <li>
                Pal, D., Triyason, T., & Funikul, S. (2017, December). Smart homes and quality of life for the elderly: a systematic 
                review. In 2017 IEEE international symposium on multimedia (ISM) (pp. 413-419). IEEE. 
              </li>
              <li>
                Etemad-Sajadi, R., & Gomes Dos Santos, G. (2019). Senior citizens’ acceptance of connected health technologies in their homes. 
                International journal of health care quality assurance, 32(8), 1162-1174. 
              </li>
              <li>
                Liu, L., Stroulia, E., Nikolaidis, I., Miguel-Cruz, A., & Rincon, A. R. (2016). Smart homes and home health monitoring 
                technologies for older adults: A systematic review. International journal of medical informatics, 91, 44-59. 
              </li>
              <li>
                Reeder, B., Meyer, E., Lazar, A., Chaudhuri, S., Thompson, H. J., & Demiris, G. (2013). Framing the evidence for health 
                smart homes and home-based consumer health technologies as a public health intervention for independent aging: A systematic 
                review. International journal of medical informatics, 82(7), 565-579. 
              </li>
              <li>
                Grossi, G., Lanzarotti, R., Napoletano, P., Noceti, N., & Odone, F. (2020). Positive technology for elderly well-being: 
                A review. Pattern Recognition Letters, 137, 61-70. 
              </li>
              <li>
                Willard, S., Cremers, G., Man, Y. P., van Rossum, E., Spreeuwenberg, M., & de Witte, L. (2018). Development and testing of an online 
                community care platform for frail older adults in the Netherlands: a user-centred design. BMC geriatrics, 18(1), 1-9. 
              </li>
            </ol>
          </div>         
        </div>
      </div>
  );
};

export default Blog13;
